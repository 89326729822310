export default [
    {
        path: '/projects',
        name: 'pageProjectsList',
        meta: {
            title: 'projects.listTitle',
            description: 'Page Drafter is a free tool that helps you create professional website layouts in minutes.',
        },
        component: () => import('@/pages/projects'),
    },
    {
        path: '/projects/:id/edit',
        name: 'pageProjectsEdit',
        meta: { title: 'projects.editFormTitle' },
        component: () => import('@/pages/projects/id/edit'),
    },
    {
        path: '/projects/folder/:id',
        name: 'pageFolderProjectsList',
        meta: { title: 'projects.listTitle' },
        component: () => import('@/pages/projects/folder'),
    },
]
