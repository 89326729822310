import store from '@/store'

export function hasPermission(array) {
  if (!array) return true // TODO: временно условие пока не проставим везде пермишены
  const profileHasFullAccess = store.getters['ProfileModule/profileHasFullAccess']
  const profilePermissions = store.getters['ProfileModule/profilePermissions']

  if (!Array.isArray(array)) {
    throw new Error(`need roles! Like ['admin','editor']`)
  }

  if (profileHasFullAccess) return true

  return array.some(role => profilePermissions.includes(role))
}
