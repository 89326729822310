export const DATE_FORMAT = 'DD.MM.YYYY'
export const DATE_FORM_FORMAT = 'YYYY-MM-DD'
export const DATE_FULL_MONTH_FORMAT = 'DD MMMM YYYY'
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const TIME_FORMAT = 'HH:mm:ss'
export const TIME_SHORT_FORMAT = 'HH:mm'
export const DATE_TIME_FORM_FULL_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const DATE_TIME_FORM_FULL_FORMAT_DAY_FIRST = 'DD.MM.YYYY HH:mm:ss'
export const DATE_DAY_FORMAT = 'DD.MM'
export const DATE_MONTH_TIME = 'DD MMM HH:mm'
export const DATE_MONTH_FORMAT = 'MMM YYYY'
export const DATE_YEAR_MONTH_FORMAT = 'YYYY-MM'
export const DATE_DAY_MONTH_FORMAT = 'DD MMM YYYY'
export const DATE_MONTH_DAY_FORMAT = 'MMM DD. YYYY'
export const DATE_FORMAT_DASH = 'DD-MM-YYYY'
export const DATE_FORMAT_WITH_SLASH = 'HH:mm / DD.MM.YY'
export const DATE_FULL_FORMAT = 'ddd, DD MMM YYYY, HH:mm:ss'
