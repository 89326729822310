import profileRepo from '@/repository/common/profileRepository'
// import i18n from '@/plugins/vue-i18n';
// import i18nService from "@/services/i18n.service";
const state = {
  profile: null,
  profilePermissions: [],
}

const getters = {
  profile: state => state.profile,
  profilePermissions: state => state.profilePermissions,
}

const actions = {
  async loadProfile(context) {
    const { data } = await profileRepo.get()
    context.commit('setProfile', data)
    context.commit('setProfilePermissions', data.role)
    // i18n.locale = data.payload.locale || 'ru'
    // i18nService.setActiveLanguage(data.payload.locale || 'ru')
    return { profile: data }
  },
  onRecoverEmail(context, payload) {
    return profileRepo.recover(payload)
  },
  onResetPassword(context, payload) {
    const { token, ...form } = payload
    return profileRepo.resetPassword(form, { token })
  },
  onChangePassword(context, payload) {
    const { token, ...form } = payload
    return profileRepo.changePassword(form, { token })
  },
  onPublicResetPassword(context, payload) {
    const { token, ...form } = payload
    return profileRepo.publicResetPassword(form, { token })
  },
  ws_change_permission({ dispatch, getters }, id) {
    if (id === getters.profile?.id) dispatch('loadProfile')
  },
}

const mutations = {
  setProfile(state, payload = null) {
    state.profile = payload
  },
  setProfilePermissions(state, payload) {
    state.profilePermissions = [payload]
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
