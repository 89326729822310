/*
    Danger!
    You should not remove any comments from this file, if comments will be removed generator will be broken
*/

import Vue from 'vue'
import Vuex from 'vuex'

import AuthModule from '@/store/modules/Auth.module'
import ModalModule from '@/store/modules/Modal.module'
import NotificationModule from '@/store/modules/Notification.module'
import ProfileModule from '@/store/modules/Profile.module'

// Generated modules imported under this 2 comments. DO NOT REMOVE ANY COMMENTS
// __generator_imports__
import TemplatesModule from '@/store/modules/Templates.module'
import UserProjectsModule from '@/store/modules/UserProjects.module'
import PanelsModule from '@/store/modules/Panels.module'
import ProjectsModule from '@/store/modules/Projects.module'
import PanelCategoriesModule from '@/store/modules/PanelCategories.module'
import TemplateCategoriesModule from '@/store/modules/TemplateCategories.module'
import UsersModule from '@/store/modules/Users.module'
import FileModule from "@/store/modules/File.module";
import FolderModule from "@/store/modules/Folder.module";
import PagesModule from "@/store/modules/Pages.module";
import BlockCommentsModule from "@/store/modules/BlockComments.module";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    AuthModule,
    ModalModule,
    NotificationModule,
    ProfileModule,
    // Generated modules under this comment. DO NOT REMOVE ANY COMMENTS
    // __imported_entities__
TemplatesModule,
UserProjectsModule,
    PanelsModule,
    PagesModule,
    PanelCategoriesModule,
    TemplateCategoriesModule,
    UsersModule,
    FileModule,
    ProjectsModule,
    FolderModule,
    BlockCommentsModule
  },
})
