const generator = require('generate-password-browser')
export function isObject(val) {
  return typeof val === 'object' && !Array.isArray(val) && val !== null
}

export function setData(obj, data) {
  Object.keys(obj).forEach(propName => {
    if (
      Object.prototype.hasOwnProperty.call(obj, propName) &&
      Object.prototype.hasOwnProperty.call(data, propName)
    ) {
      if (data[propName] !== null && data[propName] !== undefined) {
        obj[propName] = data[propName]
      }
    }
  })
}
export function getPrepareData(data = {}, keys = []) {
  const res = JSON.parse(JSON.stringify(data))
  keys.forEach(key => {
    if (res[key] && isObject(res[key])) {
      res[key] = res[key].id
    }
    if (res[key] && Array.isArray(res[key])) {
      res[key] = res[key].map(m => m.id)
    }
  })
  return res
}

export function generateUUID(possibleArray = []) {
  const randomUUID = crypto?.randomUUID()
  let dt = new Date().getTime()
  const res =
    randomUUID ||
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0
      dt = Math.floor(dt / 16)
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
  if (possibleArray.some(uuid => uuid === res)) {
    throw new Error('Value must be unique')
  }
  return res
}

export function generatePassword(length = 30) {
  return generator.generate({
    length,
    numbers: true,
    uppercase: true,
    lowercase: true,
    symbols: '#?!@$%^&*-',
    strict: true,
  })
}

export function getUniqueArray(array, key = 'id') {
  return array.reduce(
    (acc, option) => {
      if (acc.map[option[key]])
        // если данный город уже был
        return acc // ничего не делаем, возвращаем уже собранное

      acc.map[option[key]] = true // помечаем город, как обработанный
      acc.items.push(option) // добавляем объект в массив городов
      return acc // возвращаем собранное
    },
    {
      map: {}, // здесь будут отмечаться обработанные города
      items: [], // здесь конечный массив уникальных городов
    },
  ).items // получаем конечный массив
}

export function getStringFromArray(array, key = 'name') {
  return array.map(option => option[key]).join(', ')
}

export async function copyClipboard(text){
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
}