import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/Folders/helpers'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import foldersRepository from "@/repository/generatedRepository/foldersRepository";

const state = {
  list: [],
  filteredList: [],
  filters: StorageService.get(storageKeys.FOLDERS_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  list: state => state.list,
  filteredList: state => state.filteredList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadData(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.FOLDERS_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await foldersRepository.list(preparedFilters)

    context.commit('setList', { data, filters })

    return { products: data.items, pagination: data.pagination }
  },
  async searchFolders(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      pagination: {
        limit: -1
      }
    })

    const { data } = await foldersRepository.list(preparedFilters)
    context.commit('setFilteredList', { data, filters })
    return { products: data.items, pagination: data.pagination }
  },
  async loadFolder(context, id) {
    const { data } = await foldersRepository.get(id)
    return data
  },
  createFolder(context, payload) {
    return foldersRepository.post(payload)
  },
  updateFolder(context, payload) {
    const { id, ...data } = payload
    return foldersRepository.put(id, data)
  },
  deleteFolder(context, payload) {
    return foldersRepository.delete(payload)
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.FOLDERS_FILTERS)
  },
}

const mutations = {
  setList(state, { data, filters }) {
    state.list = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilteredList(state, { data }) {
    state.filteredList = [...data.items]
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
