export default [
    {
        path: '/templates',
        name: 'pageTemplatesList',
        meta: { title: 'templates.listTitle', roles: ['ROLE_ADMIN'] },
        component: () => import('@/pages/templates'),
    },
    {
        path: '/templates/create',
        name: 'pageTemplatesCreate',
        meta: { title: 'templates.createFormTitle', roles: ['ROLE_ADMIN'] },
        component: () => import('@/pages/templates/create'),
    },
    {
        path: '/templates/:id/edit',
        name: 'pageTemplatesEdit',
        meta: { title: 'templates.editFormTitle', roles: ['ROLE_ADMIN'] },
        component: () => import('@/pages/templates/id/edit'),
    },
]
