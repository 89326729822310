export default [
    {
        path: '/template-categories',
        name: 'pageTemplateCategoriesList',
        meta: {
            title: 'templateCategories.listTitle',
            description: 'Page Drafter is a free tool that helps you create professional website layouts in minutes.',
            roles: ['ROLE_ADMIN']
        },
        component: () => import('@/pages/template-categories'),
    },
    {
        path: '/template-categories/create',
        name: 'pageTemplateCategoriesCreate',
        meta: { title: 'templateCategories.createFormTitle', roles: ['ROLE_ADMIN'] },
        component: () => import('@/pages/template-categories/create'),
    },
    {
        path: '/template-categories/:id/edit',
        name: 'pageTemplateCategoriesEdit',
        meta: { title: 'templateCategories.editFormTitle', roles: ['ROLE_ADMIN'] },
        component: () => import('@/pages/template-categories/id/edit'),
    },
]
