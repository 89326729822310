export default [
    {
        path: '/panels',
        name: 'pagePanelsList',
        meta: {
            title: 'panels.listTitle',
            description: 'Page Drafter is a free tool that helps you create professional website layouts in minutes.',
            roles: ['ROLE_ADMIN']
        },
        component: () => import('@/pages/panels'),
    },
    {
        path: '/panels/create',
        name: 'pagePanelsCreate',
        meta: { title: 'panels.createFormTitle', roles: ['ROLE_ADMIN'] },
        component: () => import('@/pages/panels/create'),
    },
    {
        path: '/panels/:id/edit',
        name: 'pagePanelsEdit',
        meta: { title: 'panels.editFormTitle', roles: ['ROLE_ADMIN'] },
        component: () => import('@/pages/panels/id/edit'),
    },
]
