export default [
    {
        path: '/users',
        name: 'pageUsersList',
        meta: {
            title: 'users.listTitle',
            description: 'Page Drafter is a free tool that helps you create professional website layouts in minutes.',
            roles: ['ROLE_ADMIN']
        },
        component: () => import('@/pages/users'),
    },
    {
        path: '/users/create',
        name: 'pageUsersCreate',
        meta: { title: 'users.createFormTitle', roles: ['ROLE_ADMIN'] },
        component: () => import('@/pages/users/create'),
    },
    {
        path: '/users/:id/edit',
        name: 'pageUsersEdit',
        meta: { title: 'users.editFormTitle', roles: ['ROLE_ADMIN'] },
        component: () => import('@/pages/users/id/edit'),
    },
]
