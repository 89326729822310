export default [
  {
    path: '/account-settings',
    name: 'pageAccountSettings',
    meta: {
      title: 'accountSettings.accountSettings',
      description: 'Page Drafter is a free tool that helps you create professional website layouts in minutes.'
    },
    component: () => import('@/pages/settings'),
  },
]
