import moment from 'moment-timezone'
import { DATE_TIME_FORMAT } from '@/constans/date'

export default class DateConverter {
  static configure(timezone) {
    this.timezone = timezone || 'Europe/Kiev'
    moment.tz.setDefault(this.timezone)
  }

  static convertUtcToUserDate(date) {
    return moment.utc(date).tz(this.timezone).toDate()
  }

  static convertUserDateToUtc(date) {
    return moment.tz(moment(date).format('YYYY-MM-DDTHH:mm:ss'), this.timezone).utc()
  }

  static convertUserDateToUtcFormat(date, format = 'YYYY-MM-DDTHH:mm:ss') {
    return DateConverter.convertUserDateToUtc(date).format(format)
  }

  static convertDateToUserDate(date) {
    return moment.utc(moment.tz(date, this.timezone).format('YYYY-MM-DDTHH:mm:ss')).toDate()
  }

  static getTodaysDateBeginning() {
    return DateConverter.convertDateToUserDate(
      moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
    )
  }

  static convertDateToUserDateFormatted(date, format = DATE_TIME_FORMAT) {
    return moment(DateConverter.convertDateToUserDate(date)).format(format)
  }
}
