export const defaultFilters = {
    pagination: {
        limit: 20,
        page: 1,
    },
    filter: {
        enabled: 1
    },
    sortBy: ['enabled', 'id'],
    sortDesc: [true, true],
    clientOptions: {
        filterPanelExpanded: false,
    },
}

export default function customUserLabel(option) {
    return `${option.name} ${option.lastName}`
}
