import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import i18n from '@/plugins/vue-i18n'
import generatedRoutes from "./generated-routes";
import auth from './auth'
// import onLoginToken from '@/store/modules/Auth.module'
import landing from "./landing";
Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...landing,
    ...auth,
    {
      path: '/admin',
      redirect: '/dashboard',
      component: () => import('@/layouts/LayoutAdmin'),
      children: [
        {
          path: '/dashboard',
          name: 'pageMain',
          meta: { title: 'page.pageMain' },
          component: () => import('@/pages/admin/dashboard'),
        },
        ...generatedRoutes,
        {
          path: '/mobile-error',
          name: 'mobileError',
          meta: { title: 'mobileError'},
          component: () => import('@/components/mobile-error-layout.vue'),
        },
        {
          path: '/403',
          name: 'pageError403',
          meta: { title: 'page.pageError403', noRedirect: true },
          component: () => import('@/pages/error/Error-403.vue'),
        },
        {
          path: '/404',
          name: 'pageError404',
          meta: { title: 'error.error404Title', noRedirect: true },
          component: () => import('@/pages/error/Error-404.vue'),
        },
      ],
    },

    // 404 page must be placed at the end !!!
    { path: '*', redirect: '/404', hidden: true },
  ],
})

const whiteList = ['landingPage', 'signUpPage', 'loginPage', 'restorePage', 'recoveryPassword', 'publicAuth'] // no redirect whitelist

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  store.dispatch('AuthModule/onVerifyAuth').then(() => {
    const getPathname = window.location.pathname
    if (getPathname.includes('public/token')) {
      const tokenFromPath = getPathname.split('/').slice(-1).join('')
      const tokenString = JSON.stringify(tokenFromPath, null, 2).split('"').join('')
      store.dispatch('AuthModule/onLoginToken', tokenString).then(() => {
        const isAuthenticated = store.getters['AuthModule/isAuthenticated']
        if (whiteList.includes(to.name) && isAuthenticated) {
          next({ name: 'pageProjectsList' })
        }
      })
    }
    const isAuthenticated = store.getters['AuthModule/isAuthenticated']
    if (whiteList.includes(to.name) && !isAuthenticated) {
      next()
      return
    }

    if (!isAuthenticated) {
      next({ name: 'landingPage' })
      return
    }

    if (whiteList.includes(to.name) && isAuthenticated) {
      next({ name: 'pageProjectsList' })
      return
    }

    if (store.getters['ProfileModule/profileHasFullAccess']) {
      next()
      return
    }

    const profilePermissions = store.getters['ProfileModule/profilePermissions']
    const hasAccessPage =
        !Array.isArray(to.meta.roles) || to.meta.roles.some(role => profilePermissions.includes(role))

    if (!hasAccessPage) {
      next({ name: 'pageError403' })
      return
    }

    next()
  })
  // close modals on change route
  if (to.name !== from.name) store.dispatch('ModalModule/closeAll')

  // set page tab name
  document.title = i18n.t(`${to.meta.title}`)
})

export default router
