/*
    Danger!
    You should not remove any comments from this file, if comments will be removed generator will be broken
*/

// __generator_imports__
import templatesRoute from './templates'
import userProjectsRoute from './user-projects'
import panelsRoute from './panels'
import projectsRoute from './projects'
import panelCategoriesRoute from './panel-categories'
import templateCategoriesRoute from './template-categories'
import usersRoute from './users'
import pageAccountSettings from './settings'

const routes = [
// __imported_entities__
...templatesRoute,
...userProjectsRoute,
...projectsRoute,
...panelsRoute,
...panelCategoriesRoute,
...templateCategoriesRoute,
...usersRoute,
  ...pageAccountSettings,

]

export default routes;
