export const defaultFilters = {
    pagination: {
        limit: 20,
        page: 1,
    },
    filter: {
        createdAt: null,
        active: null
    },
    sortBy: ['active', 'id'],
    sortDesc: [true, true],
    clientOptions: {
        filterPanelExpanded: true,
    },
}
