export default [
    {
        path: '/panel-categories',
        name: 'pagePanelCategoriesList',
        meta: {
            title: 'panelCategories.listTitle',
            description: 'metaDescription',
            roles: ['ROLE_ADMIN']
        },
        component: () => import('@/pages/panel-categories'),
    },
    {
        path: '/panel-categories/create',
        name: 'pagePanelCategoriesCreate',
        meta: { title: 'panelCategories.createFormTitle', roles: ['ROLE_ADMIN'] },
        component: () => import('@/pages/panel-categories/create'),
    },
    {
        path: '/panel-categories/:id/edit',
        name: 'pagePanelCategoriesEdit',
        meta: { title: 'panelCategories.editFormTitle', roles: ['ROLE_ADMIN'] },
        component: () => import('@/pages/panel-categories/id/edit'),
    },
]
