<template>
  <span class="material-symbols-outlined" v-bind="$attrs" v-on="$listeners" :style="getStyles">
    <slot />
  </span>
</template>

<script>
export default {
  name: "ui-icon",
  props: {
    fill: {
      type: Boolean,
      default: false,
    },
    weight: {
      type: String,
      default: '400',
      validator(type) {
        return ['100', '200', '300', '400', '500', '600', '700'].includes(type)
      },
    },
    color: {
      type: String,
      default: 'var(--v-black-darken1)'
    },
    size: {
      type: String,
      default: '20'
    }
  },
  computed: {
    getStyles() {
      return [
        {
          fontVariationSettings: `'FILL' ${this.fill ? 1 : 0}, 'wght' ${this.weight}, 'GRAD' 0`,
          color: this.color,
          fontSize: this.size + 'px',
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.material-symbols-outlined {
  font-size: 20px;
}
</style>