import blockCommentsRepository from "@/repository/generatedRepository/blockCommentsRepository";

const actions = {
  loadItem(context, id) {
    const { data } = blockCommentsRepository.get(id)
    return data
  },
  createItem(context, payload) {
    return blockCommentsRepository.post(payload)
  },
  updateItem(context, payload) {
    const { id, ...data } = payload
    return blockCommentsRepository.put(id, data)
  },
  deleteItem(context, payload) {
    return blockCommentsRepository.delete(payload)
  },
}

export default {
  namespaced: true,
  actions,
}
