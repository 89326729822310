export default [
    {
        path: "/",
        component: () => import('@/layouts/LayoutLanding'),
        children: [
            {
                path: '/',
                name: 'landingPage',
                meta: { title: 'homePage.listTitle',
                    description: 'Page Drafter is a free tool that helps you create professional website layouts in minutes.',
                    noRedirect: true },
                component: () => import('@/pages/public/HomeView.vue'),
            },
        ]
    },
]
