import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/TemplateCategories/helpers'
import templateCategoriesRepository from '@/repository/generatedRepository/templateCategoriesRepository'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import i18n from "@/plugins/vue-i18n";

const state = {
  list: [],
  preparedList: [],
  filters: StorageService.get(storageKeys.TEMPLATE_CATEGORIES_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  list: state => state.list,
  preparedList: state => state.preparedList,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadData(context, filters) {
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.TEMPLATE_CATEGORIES_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)

    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      cfFilter: appliedCfFilters,
    })

    const { data } = await templateCategoriesRepository.list(preparedFilters)

    context.commit('setList', { data, filters })

    return { products: data.items, pagination: data.pagination }
  },
  async searchTemplateCategories(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
      pagination: {
        limit: -1
      }
    })

    const { data } = await templateCategoriesRepository.list(preparedFilters)
    context.commit('setPreparedList', { data })
    return { products: data.items, pagination: data.pagination }
  },
  async loadItem(context, id) {
    const { data } = await templateCategoriesRepository.get(id)
    return data
  },
  createItem(context, payload) {
    return templateCategoriesRepository.post(payload)
  },
  updateItem(context, payload) {
    const { id, ...data } = payload
    return templateCategoriesRepository.put(id, data)
  },
  deleteItem(context, payload) {
    return templateCategoriesRepository.delete(payload)
  },
  async loadItemOneC(context, id) {
    const { data } = await templateCategoriesRepository.getOneC(id)
    return { product: data }
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.TEMPLATE_CATEGORIES_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.TEMPLATE_CATEGORIES_FILTERS, updatedFilters)
  },
}

const mutations = {
  setList(state, { data, filters }) {
    state.list = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setPreparedList(state, { data }) {
    let preparedList = data.items.filter(item => item.template_count > 0)
    let summ = preparedList.reduce((acc, category) => {
      acc += category.template_count
      return acc
    }, 0)
    preparedList.unshift({id: null, title: i18n.tc('base.all'), template_count: summ})
    state.preparedList = [...preparedList]
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
