import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  icons: {
    iconfont: 'md'
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#7E4DFB',
        main900: '#0000D6',
        main600: '#5122F2',
        main500: '#5F26F9',
        main400: '#7E4DFB',
        main300: '#9970FC',
        main200: '#B89BFC',
        main100: '#D5C3FC',
        main50: '#EFE7FE',
        black900: '#202021',
        black800: '#414142',
        black700: '#5F6061',
        black400: '#9D9D9D',
        secondary: '#C4C4C4',
        stroke: '#E2E5EB',
        white: '#FFFFFF',
        grey: '#F9FAFB',
        red: '#DF494B',
        green: '#48C3AA',
        green100: '#2D7F6E',
        background: '#FAFAFD',
        black: {
          base: '#000000',
          lighten1: '#ABABAB',
          lighten2: '#BCBCBC',
          lighten3: '#CDCDCD',
          lighten4: '#DDDDDD',
          lighten5: '#E6E6E6',
          lighten6: '#EEEEEE',
          lighten7: '#F6F6F6',
          darken1: '#8D8D8D',
          darken2: '#707070',
          darken3: '#525252',
          darken4: '#434343',
          darken5: '#353535',
          darken6: '#262626',
          darken7: '#171717'
        }
      },
    },
  },
})

export default vuetify
