import AuthRepo from '@/repository/auth'
import { destroyTokenData, getTokenData, saveTokenData } from '@/services/token.service'
import DateConverter from '@/models/DateConverter'
// import notificationSocket from '@/plugins/notification.socket'
const state = {
  isAuthenticated: !!getTokenData(),
}

const getters = {
  isAuthenticated: state => state.isAuthenticated,
}

const actions = {
  onLogin(context, payload) {
    return new Promise((resolve, reject) => {
      AuthRepo.login(payload)
        .then(({ data }) => {
          context.commit('setAuth', data)
          resolve(data)
          // notificationSocket(data.token)
        })
        .catch(data => {
          reject(data)
        })
    })
  },
  onLoginToken(context, payload) {
    return new Promise((resolve, reject) => {
      AuthRepo.registrationToken(payload)
        .then(({ data }) => {
          context.commit('setAuth', data)
          resolve()
          // notificationSocket(data.token)
        })
        .catch(data => {
          console.log('onLoginToken --- ERROR',data)
          reject(data)
        })
    })
  },
  onRegistration(context, payload) {
    return new Promise((resolve, reject) => {
      AuthRepo.registration(payload)
        .then(({ data }) => {
          resolve(data)
          // notificationSocket(data.token)
        })
        .catch(data => {
          reject(data)
        })
    })
  },
  onSendTokenAgain(context, payload) {
    return new Promise((resolve, reject) => {
      AuthRepo.registrationTokenAgain(payload)
        .then(({ data }) => {
          resolve(data)
          // notificationSocket(data.token)
        })
        .catch(data => {
          reject(data)
        })
    })
  },
  onLogout(context) {
    return new Promise((resolve, reject) => {
      const tokenData = getTokenData()
      AuthRepo.logout({ tokenData })
        .then(({ data }) => {
          // location.href = '/auth/login'
          context.commit('purgeAuth')
          context.commit('ProfileModule/setProfile', null, { root: true })
          resolve(data)
        })
        .catch(data => {
          reject(data)
        })
    })
  },
  onRefreshToken(context) {
    return new Promise((resolve, reject) => {
      const tokenData = getTokenData()
      const refreshToken = tokenData ? JSON.parse(tokenData).refreshToken : ''
      AuthRepo.refreshToken({ refreshToken })
        .then(({ data }) => {
          context.commit('setAuth', data)
          resolve(data)
          // notificationSocket(data.token)
        })
        .catch(data => {
          reject(data)
        })
    })
  },
  onVerifyAuth(context) {
    if (getTokenData()) {
      if (!context.rootGetters['ProfileModule/profile']) return context.dispatch('reLoadProfile')
    } else {
      context.commit('purgeAuth')
    }
  },
  async reLoadProfile(context) {
    return new Promise(resolve => {
      context.dispatch('ProfileModule/loadProfile', null, { root: true }).then(({ profile }) => {
        DateConverter.configure()
        resolve(profile)
        // notificationSocket(JSON.parse(getTokenData())?.token)
        // i18n.locale = data.payload.locale || 'ru'
        // i18nService.setActiveLanguage(data.payload.locale || 'ru')
      })
    })
  },
}

const mutations = {
  setAuth(state, data) {
    state.isAuthenticated = true
    saveTokenData(JSON.stringify(data))
  },
  purgeAuth(state) {
    state.isAuthenticated = false
    destroyTokenData()
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
