<template>
  <keep-alive>
    <ui-card v-if="isShow" class="py-6 custom-shadow">
      <slot />
    </ui-card>
  </keep-alive>
</template>

<script>
export default {
  name: 'ui-filter-entity',
  props: {
    isShowFilter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isShow() {
      return this.isShowFilter
    },
  },
}
</script>

<style scoped>
.custom-shadow{
  box-shadow: 0px 4px 5px 0px rgba(188, 188, 188, 0.14)!important;
}
</style>
