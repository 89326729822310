import Vue from 'vue'

import {getDateByTimeZone, formatDate, formatDateFromNow, localDate} from '@/helpers/date'
import DateConverter from '@/models/DateConverter'
import { formatAsPercentage, formatNumber } from '@/helpers/number'
import {
  renderFullName,
  renderValue,
  renderValueByKey,
  renderValueJoinBySymbol,
  renderValueYesNo,
} from '@/helpers/render-value'
import {stringCroppedView} from "@/helpers/cropString";
import {formatBytes} from "@/helpers/formatBytes";

Vue.filter('getDateTimeZone', str => getDateByTimeZone(str))
Vue.filter('formatDate', formatDate)
Vue.filter('localDate', localDate)
Vue.filter('formatBytes', formatBytes)
Vue.filter('cropString', stringCroppedView)
Vue.filter('formatDateFromNow', formatDateFromNow)
Vue.filter('convertToLocalDate', DateConverter.convertDateToUserDateFormatted)
Vue.filter('formatAsPercentage', formatAsPercentage)
Vue.filter('formatNumber', formatNumber)
Vue.filter('renderValue', renderValue)
Vue.filter('renderFullName', renderFullName)
Vue.filter('renderValueByKey', renderValueByKey)
Vue.filter('renderValueYesNo', renderValueYesNo)
Vue.filter('renderValueJoinBySymbol', renderValueJoinBySymbol)
